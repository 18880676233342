import React, { useEffect } from 'react';
import { UpcomingEvents } from './UpcomingEventsLanding';
import { Flex, Text, Heading } from '@spothero/ui';
import { useSelector } from 'react-redux';
import StorageUtils from '@spothero/utils/storage';
import SegmentUtils from 'utils/segment';
import { NotGoingToEvent } from '../not-going-to-event/NotGoingToEvent';
export const VenueEventSEMEdit = () => {
    const title = useSelector((state) => state.destination.data.title);
    const searchString = useSelector((state) => state.searchRequest.search_string);
    const partner = useSelector((state) => state.searchRequest.partner);
    useEffect(() => {
        const userLatLng = StorageUtils.get('sh-user-location', 'session');
        SegmentUtils.track({
            event: 'Event List Display',
            properties: {
                /* eslint-disable camelcase */
                ...(userLatLng && { user_location: userLatLng }),
                ...(searchString && { search_query: searchString }),
                destination: title,
                /* eslint-enable camelcase */
            },
        });
    }, [searchString, title]);
    const applePartnerTitle = partner === 'apple' ? 'Book Parking for ' : '';
    return (
    // Height comes from header height plus height of the x and events
    <Flex overflow="auto" height={'calc(100vh - 90px)'} paddingX="4" data-testid="VenueEventSEMEdit" flexDirection="column">
            <Heading as="h3" paddingBottom="4">
                {applePartnerTitle}
                {title}
                {applePartnerTitle ? '' : ' Parking'}
            </Heading>
            <Text fontSize="sm" fontWeight="medium">
                Upcoming Events
            </Text>
            <UpcomingEvents />
            <NotGoingToEvent />
        </Flex>);
};
