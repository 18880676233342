import React from 'react';
import { Box, Icon, Text } from '@spothero/ui';
import { useDispatch, useSelector } from 'react-redux';
import { getParkingType } from 'store/selectors/selectors';
import { GenericListItem } from 'pages/search/components/events/event-list-item/GenericListItem';
import TicketEvent from '@spothero/icons/ticket-event';
import { OPTIMIZELY_VARIATION_REFRESHED, useFeatureVariation, } from 'plugins/optimizely/hooks/use-feature-variation';
import { IMMEDIATE_FEATURES } from 'utils/experiment';
import { setSearchComponentEventView, setSearchComponentIsOpen, } from 'store/search/search-actions';
import { SearchPageView } from 'utils/search-page-view';
export const ViewUpcomingEventsList = () => {
    const dispatch = useDispatch();
    const showEventEdit = () => {
        dispatch(setSearchComponentEventView('selectWFilter'));
        dispatch(setSearchComponentIsOpen(true));
    };
    const parkingType = useSelector(getParkingType);
    const isMonthly = useSelector((state) => state.searchRequest.monthly);
    const searchType = useSelector((state) => state.search.data.searchType);
    const searchView = useSelector((state) => state.search.data.view);
    const canHaveEvents = useSelector((state) => state.destination.data.can_have_events);
    const eventsExist = useSelector((state) => Array.isArray(state.destination.data.events)
        ? state.destination.data.events.length
        : 0);
    const isSearchRevampRefreshedVariation = useFeatureVariation(IMMEDIATE_FEATURES.ACQUISITION_MOBILE_WEB_SEARCH_REVAMP) === OPTIMIZELY_VARIATION_REFRESHED;
    // Should I add a check for if there are events or not?
    const show = Boolean((searchView === SearchPageView.MOBILE_LIST ||
        searchView === SearchPageView.MOBILE_MAP) &&
        isSearchRevampRefreshedVariation &&
        canHaveEvents &&
        eventsExist &&
        searchType === 'venue' &&
        !isMonthly);
    return (show && (<GenericListItem handleClick={showEventEdit} asAnchor={false} sx={{
            '&::after': {},
            backgroundColor: 'white',
            borderRadius: '10px',
            padding: '2',
            marginTop: '2',
            marginBottom: '4',
            boxShadow: '1px 2px 3px 0 rgba(0, 45, 91, 0.1)', // Copied from .SpotList
        }}>
                <Box className="event-date" // Used for hover state
     minWidth="36px" width="36px" height="38px" marginRight="2" rounded="md" overflow="hidden" backgroundColor="blue.100" display="flex" flexDirection="column" justifyContent="center" alignItems="center" transform={parkingType === 'event' ? undefined : 'scale(0.85)'}>
                    <Icon as={TicketEvent} fill="primary.default" height="5" width="5"/>
                </Box>
                <Text fontSize="sm" fontWeight="semibold">
                    View Upcoming Events
                </Text>
            </GenericListItem>));
};
